:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

.mainTopArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainTotalArea, .mainTotalArea1 {
    display: grid;
    grid-template-rows: 20% 50% 20%;
    grid-template-areas: "a" "b" "c";
    width: 100%;
    height: 98vh;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 375px) {
  .mainTotalArea, .mainTotalArea1 {
    grid-template-rows: 26% 53% 20%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 376px) {
  .mainTotalArea, .mainTotalArea1 {
    grid-template-rows: 20% 44% 20%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 376px) and (max-width: 385px) {
  .mainTotalArea, .mainTotalArea1 {
    grid-template-rows: 23% 45% 24%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 386px) and (max-width: 394px) {
  .mainTotalArea, .mainTotalArea1 {
    grid-template-rows: 25% 46% 26%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 410px) and (max-width: 415px) {
  .mainTotalArea, .mainTotalArea1 {
    grid-template-rows: 24% 46% 26%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 425px) and (max-width: 429px) {
  .mainTotalArea, .mainTotalArea1 {
    grid-template-rows: 24% 46% 30%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 430px) and (max-width: 435px) {
  .mainTotalArea, .mainTotalArea1 {
    grid-template-rows: 24% 46% 34%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 470px) and (max-width: 481px) {
  .mainTotalArea, .mainTotalArea1 {
    grid-template-rows: 22% 46% 21%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainTotalArea, .mainTotalArea1 {
    display: grid;
    grid-template-rows: 24% 40% 50%;
    grid-template-areas: "a" "b" "c";
    -moz-column-gap: 7px;
         column-gap: 7px;
    width: 100%;
    height: 98vh;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1025px) {
  .mainTotalArea, .mainTotalArea1 {
    grid-template-rows: 26% 52% 24%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 11250px) {
  .mainTotalArea, .mainTotalArea1 {
    grid-template-rows: 26% 52% 24%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mainTotalArea, .mainTotalArea1 {
    display: grid;
    grid-template-rows: 22% 50% 29%;
    grid-template-areas: "a" "b" "c";
    width: 100%;
    height: 98vh;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainTotalArea, .mainTotalArea1 {
    display: grid;
    grid-template-rows: 22% 50% 29%;
    grid-template-areas: "a" "b" "c";
    width: 100%;
    height: 98vh;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media only screen and (min-width:2303px) {
  .mainTotalArea, .mainTotalArea1 {
    display: grid;
    grid-template-rows: 23% 52% 29%;
    grid-template-areas: "a" "b" "c";
    width: 100%;
    height: 98vh;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.mainTotalArea1 {
  zoom: 0.9;
}

.mainHeaderWrapper {
  grid-area: a;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 20vh;
  background-color: black;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainHeaderWrapper {
    height: 17vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 375px) {
  .mainHeaderWrapper {
    height: 24vh;
    width: 100%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 375px) and (max-width: 385px) {
  .mainHeaderWrapper {
    height: 21vh;
    width: 100%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 386px) and (max-width: 394px) {
  .mainHeaderWrapper {
    height: 22vh;
    width: 100%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 410px) and (max-width: 415px) {
  .mainHeaderWrapper {
    height: 22vh;
    width: 100%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 425px) and (max-width: 429px) {
  .mainHeaderWrapper {
    height: 22vh;
    width: 100%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 430px) and (max-width: 435px) {
  .mainHeaderWrapper {
    height: 22vh;
    width: 100%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 470px) and (max-width: 481px) {
  .mainHeaderWrapper {
    height: 21vh;
    width: 100%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 600px) and (max-width: 1438px) {
  .mainHeaderWrapper {
    height: 18vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 1439px) and (max-width: 1911px) {
  .mainHeaderWrapper {
    height: 16vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 1912px) and (max-width:2302px) {
  .mainHeaderWrapper {
    height: 15.5vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width:2303px) {
  .mainHeaderWrapper {
    height: 17vh;
  }
}

.mainHeaderTitleText {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: white;
  font-size: 30px;
  font-weight: 300;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 3vh;
  width: 98%;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainHeaderTitleText {
    font-size: 26px;
    margin-top: 1vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainHeaderTitleText {
    font-size: 28px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mainHeaderTitleText {
    font-size: 26px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainHeaderTitleText {
    font-size: 28px;
  }
}
@media only screen and (min-width:2303px) {
  .mainHeaderTitleText {
    font-size: 30px;
  }
}

.mainHeaderSubTitleText {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: white;
  font-size: 22px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  width: 98%;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainHeaderSubTitleText {
    font-size: 20px;
    justify-content: flex-start;
    margin-left: 3vw;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainHeaderSubTitleText {
    font-size: 22px;
    margin-left: 3vw;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mainHeaderSubTitleText {
    font-size: 20px;
    margin-left: 3vw;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainHeaderSubTitleText {
    font-size: 26px;
    margin-left: 3vw;
  }
}
@media only screen and (min-width:2303px) {
  .mainHeaderSubTitleText {
    font-size: 30px;
    margin-left: 3vw;
  }
}

.mainHeaderTitleText {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: white;
  font-size: 30px;
  font-weight: 300;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 3vh;
  width: 98%;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainHeaderTitleText {
    font-size: 26px;
    margin-top: 1vh;
    margin-left: 3vw;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainHeaderTitleText {
    font-size: 28px;
    margin-left: 3vw;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mainHeaderTitleText {
    font-size: 30px;
    margin-left: 3vw;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainHeaderTitleText {
    font-size: 32px;
    margin-left: 3vw;
  }
}
@media only screen and (min-width:2303px) {
  .mainHeaderTitleText {
    font-size: 36px;
    margin-left: 3vw;
  }
}

.mainHeaderSubTitleTextLocation {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: white;
  font-size: 16px;
  font-weight: 300;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 12px;
  width: 98%;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainHeaderSubTitleTextLocation {
    font-size: 13px;
    margin-top: 1vh;
    margin-left: 3vw;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainHeaderSubTitleTextLocation {
    font-size: 14px;
    margin-left: 3vw;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mainHeaderSubTitleTextLocation {
    font-size: 15px;
    margin-left: 3vw;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainHeaderSubTitleTextLocation {
    font-size: 16px;
    margin-left: 3vw;
  }
}
@media only screen and (min-width:2303px) {
  .mainHeaderSubTitleTextLocation {
    font-size: 20px;
    margin-left: 3vw;
  }
}

.mainWeatherWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainWeatherWrapper {
    height: 5vh;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    width: 99%;
    margin-left: 3vw;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainWeatherWrapper {
    height: 3vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mainWeatherWrapper {
    height: 2vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainWeatherWrapper {
    height: 3vh;
  }
}
@media only screen and (min-width:2303px) {
  .mainWeatherWrapper {
    height: 4vh;
  }
}

.mainWeather {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2vh;
  width: 100%;
  height: 3vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: white;
  font-size: 12px;
  margin-bottom: 2vh;
  margin-right: 2vw;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainWeather {
    margin-right: 0.1vw;
    justify-content: flex-start;
    font-size: 10px;
    width: 98%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 370px) {
  .mainWeather {
    font-size: 10px;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainWeather {
    font-size: 11px;
  }
}
@media only screen and (min-width:2303px) {
  .mainWeather {
    font-size: 20px;
  }
}

.mainWeatherIcon {
  width: 50px;
  height: 50px;
  margin-right: 1vw;
  color: white;
}
@media (max-width: 370px) {
  .mainWeatherIcon {
    width: 30px;
    height: 30px;
    color: white;
  }
}

.mainWeatherText {
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.mainFlagsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10vh;
  background-color: black;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainFlagsWrapper {
    height: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainFlagsWrapper {
    height: 5vh;
  }
}

.mainFlagArea {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 98%;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainFlagArea {
    justify-content: center;
  }
}

.midFlag {
  width: 30px;
  height: 30px;
  margin-right: 1vw;
  cursor: pointer;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .midFlag {
    width: 24px;
    height: 24px;
  }
}

.mainAreaB {
  grid-area: b;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 52vh;
  width: 100%;
}
@media (min-width: 383px) and (max-width: 385px) {
  .mainAreaB {
    height: 55vh;
  }
}
@media (min-width: 410px) and (max-width: 415px) {
  .mainAreaB {
    height: 58vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainAreaB {
    height: 50vh;
    width: 99%;
  }
}

.mainAreaBWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 97%;
  height: 58vh;
  border: 1px solid rgb(223, 212, 212);
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 380px) and (max-width: 385px) {
  .mainAreaBWrapper {
    width: 97%;
    height: 46vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 385px) and (max-width: 393px) {
  .mainAreaBWrapper {
    width: 97%;
    height: 48vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 410px) and (max-width: 415px) {
  .mainAreaBWrapper {
    width: 97%;
    height: 48vh;
    border-radius: 8px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 425px) and (max-width: 429px) {
  .mainAreaBWrapper {
    width: 97%;
    height: 50vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 470px) and (max-width: 481px) {
  .mainAreaBWrapper {
    width: 97%;
    height: 45vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainAreaBWrapper {
    width: 97%;
    height: 56vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1025px) {
  .mainAreaBWrapper {
    height: 57vh;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1026px) and (max-width: 1115px) {
  .mainAreaBWrapper {
    height: 57vh;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mainAreaBWrapper {
    width: 99%;
    height: 48vh;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainAreaBWrapper {
    width: 98%;
    height: 50vh;
    border-radius: 8px;
  }
}
@media only screen and (min-width:2303px) {
  .mainAreaBWrapper {
    width: 97%;
    height: 50vh;
    border-radius: 8px;
  }
}

.mainAreaC {
  grid-area: c;
  margin-top: 2vh;
  width: 90%;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainAreaC {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainAreaC {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mainAreaC {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainAreaC {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .mainAreaC {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 0.5vh;
  }
}

.mainAboutMeText {
  font-size: 18px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  width: 98%;
  height: 2vh;
  margin-left: 2vw;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainAboutMeText {
    font-size: 14px;
    margin-left: 1vw;
    height: 1vh;
    margin-top: 1vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainAboutMeText {
    font-size: 22px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    margin-left: 1vw;
    height: 2vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1025px) {
  .mainAboutMeText {
    height: 1vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mainAboutMeText {
    font-size: 24px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    margin-left: 1vw;
    height: 2vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainAboutMeText {
    font-size: 26px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    margin-left: 1vw;
    height: 2vh;
  }
}
@media only screen and (min-width:2303px) {
  .mainAboutMeText {
    font-size: 32px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    margin-left: 1vw;
    height: 2vh;
  }
}

.mainMidPhoto {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  height: 48vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainMidPhoto {
    width: 100%;
    height: 20vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainMidPhoto {
    width: 100%;
    height: 25vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mainMidPhoto {
    width: 100%;
    height: 25vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainMidPhoto {
    width: 100%;
    height: 25vh;
  }
}
@media only screen and (min-width:2303px) {
  .mainMidPhoto {
    width: 100%;
    height: 25vh;
  }
}
.mainMidPhoto .mainMidPhotoImg {
  width: 60%;
  height: 70%;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainMidPhoto .mainMidPhotoImg {
    width: 20vh;
    height: 20vh;
    border-radius: 50%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainMidPhoto .mainMidPhotoImg {
    width: 25vh;
    height: 25vh;
    border-radius: 50%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mainMidPhoto .mainMidPhotoImg {
    width: 25vh;
    height: 25vh;
    border-radius: 50%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainMidPhoto .mainMidPhotoImg {
    width: 25vh;
    height: 25vh;
    border-radius: 50%;
  }
}
@media only screen and (min-width:2303px) {
  .mainMidPhoto .mainMidPhotoImg {
    width: 25vh;
    height: 25vh;
    border-radius: 50%;
  }
}

.mainMidInfoText {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 20px;
  padding: 5px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainMidInfoText {
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainMidInfoText {
    font-size: 15px;
    width: 80%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mainMidInfoText {
    font-size: 18px;
    width: 80%;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainMidInfoText {
    font-size: 20px;
    width: 80%;
  }
}
@media only screen and (min-width:2303px) {
  .mainMidInfoText {
    font-size: 24px;
    width: 80%;
  }
}

.mainMidButtonsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainMidButtonsWrapper {
    width: 50%;
    justify-content: space-evenly;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mainMidButtonsWrapper {
    width: 50%;
    justify-content: space-evenly;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainMidButtonsWrapper {
    width: 50%;
    justify-content: space-evenly;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .mainMidButtonsWrapper {
    width: 50%;
    justify-content: space-evenly;
    margin-bottom: 5px;
  }
}

.mainInfoButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 6px;
  background-color: white;
  width: 9vw;
  height: 25px;
  padding: 2px;
  margin-left: 8px;
  cursor: pointer;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainInfoButton {
    width: auto;
    height: 20px;
    margin: 3px;
    padding: 3px;
  }
}

.mainIcons {
  width: 18px;
  height: 18px;
  stroke-width: 2;
}

.mainIconText {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  margin-left: 1vw;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainIconText {
    font-size: 12px;
  }
}
@media only screen and (min-width:2303px) {
  .mainIconText {
    font-size: 22px;
  }
}

.mainInfoButtonChat {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 6px;
  background-color: white;
  width: auto;
  height: 25px;
  padding: 2px;
  margin-left: 10px;
  cursor: pointer;
}
.mainInfoButtonChat:hover {
  background-color: rgb(225, 176, 68);
  color: white;
  box-shadow: 5px 5px 12px 2px rgba(71, 70, 70, 0.2);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainInfoButtonChat {
    height: 20px;
    width: auto;
    padding: 2px;
    margin: 3px;
    padding: 3px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainInfoButtonChat {
    height: 20px;
    width: auto;
    padding: 2px;
    margin: 3px;
    padding: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mainInfoButtonChat {
    height: 25px;
    width: auto;
    padding: 2px;
    margin: 3px;
    padding: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainInfoButtonChat {
    height: 20px;
    width: auto;
    padding: 2px;
    margin: 3px;
    padding: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .mainInfoButtonChat {
    height: 25px;
    width: auto;
    padding: 2px;
    margin: 3px;
    padding: 5px;
  }
}

.mainIconTextChat {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  margin-left: 1vw;
  width: auto;
  white-space: nowrap;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainIconTextChat {
    font-size: 12px;
  }
}
@media only screen and (min-width:2303px) {
  .mainIconTextChat {
    font-size: 22px;
  }
}

.chat-container {
  position: absolute;
  top: 42vh;
  right: 15vw;
  width: 500px;
  height: 40vh;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #f7f7f7;
  box-shadow: 12px 12px 10px 2px rgba(0, 0, 0, 0.5);
  z-index: 99;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .chat-container {
    width: 300px;
    height: 38vh;
    top: 64vh;
    right: 5vw;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .chat-container {
    width: 400px;
    height: 50vh;
  }
}

.chat-messages {
  padding: 16px;
  height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .chat-messages {
    height: 30vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .chat-messages {
    height: 41.5vh;
  }
}

.message {
  padding: 10px;
  border-radius: 8px;
  max-width: 75%;
}

.user {
  align-self: flex-end;
  background-color: green;
  color: white;
}

.assistant {
  align-self: flex-start;
  background-color: #e5e5e5;
  color: black;
}

.typing-indicator {
  display: flex;
  gap: 4px;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #333;
  border-radius: 50%;
  animation: blink 1s infinite both;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes blink {
  0%, 100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
}
.message-form {
  display: flex;
  border-top: 1px solid #ddd;
}

.message-form input {
  flex: 1;
  padding: 10px;
  border: none;
  outline: none;
}

.message-form button {
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.mainMidSelectWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
  width: 100%;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainMidSelectWrapper {
    width: 100%;
    margin-top: 0.1vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainMidSelectWrapper {
    width: 100%;
    margin-top: 0.1vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mainMidSelectWrapper {
    width: 100%;
    margin-top: 0.1vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainMidSelectWrapper {
    width: 100%;
    margin-top: 0.1vh;
  }
}
@media only screen and (min-width:2303px) {
  .mainMidSelectWrapper {
    width: 100%;
    margin-top: 0.1vh;
  }
}

.mainMidSelectBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  height: 6vh;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 4px;
  padding: 15px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainMidSelectBox {
    padding: 4px;
    width: 95%;
    justify-content: center;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainMidSelectBox {
    padding: 4px;
    width: 95%;
    justify-content: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mainMidSelectBox {
    padding: 4px;
    width: 95%;
    justify-content: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainMidSelectBox {
    padding: 4px;
    width: 95%;
    justify-content: center;
  }
}
@media only screen and (min-width:2303px) {
  .mainMidSelectBox {
    padding: 6px;
    width: 95%;
    justify-content: center;
  }
}

.mainMidSelectButton, .mainMidSelectButtonON {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 6px;
  width: auto;
  height: 25px;
  padding: 3px;
  margin-left: 1vw;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  cursor: pointer;
  white-space: nowrap;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainMidSelectButton, .mainMidSelectButtonON {
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainMidSelectButton, .mainMidSelectButtonON {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mainMidSelectButton, .mainMidSelectButtonON {
    font-size: 16px;
    padding: 6px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainMidSelectButton, .mainMidSelectButtonON {
    font-size: 14px;
    padding: 8px;
  }
}
@media only screen and (min-width:2303px) {
  .mainMidSelectButton, .mainMidSelectButtonON {
    font-size: 20px;
    padding: 10px;
  }
}

.mainMidSelectButtonON {
  background-color: rgb(225, 176, 68);
  color: white;
  box-shadow: 5px 5px 12px 2px rgba(71, 70, 70, 0.2);
  cursor: pointer;
}

.mainMidInfoWrapper {
  grid-area: c;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 20vh;
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainMidInfoWrapper {
    height: 20vh;
    width: 100%;
    margin-top: 1vh;
  }
}

.mainMidInfoBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 45vh;
  border-radius: 4px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainMidInfoBox {
    width: 100%;
    margin-top: 1vh;
    height: 42vh;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mainMidInfoBox {
    width: 100%;
    height: 42vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainMidInfoBox {
    width: 100%;
    height: 36vh;
  }
}
@media only screen and (min-width:2303px) {
  .mainMidInfoBox {
    width: 97%;
    height: 36vh;
    border-radius: 8px;
  }
}

.mainMidSkillsBoxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.mainMidSkillsBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 17vh;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 4px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainMidSkillsBox {
    height: 20vh;
    width: 96%;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainMidSkillsBox {
    width: 97%;
    height: 20vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mainMidSkillsBox {
    width: 99%;
    height: 20vh;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainMidSkillsBox {
    width: 97%;
    height: 22vh;
    border-radius: 8px;
    margin-right: 1vw;
  }
}
@media only screen and (min-width:2303px) {
  .mainMidSkillsBox {
    width: 100%;
    height: 20vh;
    border-radius: 8px;
  }
}

.mainMidSkillsTitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 10%;
  height: 3vh;
  padding-left: 2vw;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 22px;
  font-weight: 500;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainMidSkillsTitle {
    font-size: 16px;
    margin-top: 1vh;
    padding-left: 1vw;
  }
}

.mainMidSkillButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  height: 7vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainMidSkillButtonWrapper {
    width: 100%;
  }
}

.mainMidSkillsButton, .mainMidSkillsButton1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 6px;
  background-color: white;
  width: auto;
  height: 25px;
  padding: 10px;
  margin-left: 1vw;
  margin-top: 1vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainMidSkillsButton, .mainMidSkillsButton1 {
    height: 18px;
    padding: 2px;
    font-size: 12px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainMidSkillsButton, .mainMidSkillsButton1 {
    height: 20px;
    padding: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainMidSkillsButton, .mainMidSkillsButton1 {
    height: 25px;
  }
}
@media only screen and (min-width:2303px) {
  .mainMidSkillsButton, .mainMidSkillsButton1 {
    height: 25px;
    font-size: 20px;
  }
}

.mainMidSkillsButton1 {
  background-color: rgb(225, 176, 68);
  color: white;
  box-shadow: 5px 5px 12px 2px rgba(71, 70, 70, 0.2);
}

.mainProjectsBoxWrapper {
  grid-area: c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.mainProjectsBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 21vh;
  border: 1px solid rgb(223, 212, 212);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainProjectsBox {
    height: 25vh;
    justify-content: flex-start;
    width: 97%;
    margin-top: 1vh;
    height: 18vh;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainProjectsBox {
    width: 97%;
    height: 20vh;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 1vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mainProjectsBox {
    width: 99%;
    height: 20vh;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 1vh;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainProjectsBox {
    width: 97%;
    height: 24vh;
    border-radius: 8px;
    margin-right: 1vw;
  }
}
@media only screen and (min-width:2303px) {
  .mainProjectsBox {
    width: 100%;
    height: 20vh;
    border-radius: 8px;
  }
}

.mainProjectsButtonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 12vh;
  width: 70%;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainProjectsButtonWrapper {
    width: 98%;
    margin-left: 1vw;
  }
}

.mainProjectsList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  height: 6vh;
  border-radius: 4px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainProjectsList {
    width: 98%;
    font-size: 12px;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainProjectsList {
    font-size: 20px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    width: 97%;
    height: 3vh;
  }
}
@media only screen and (min-width:2303px) {
  .mainProjectsList {
    font-size: 24px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    width: 97%;
    height: 3vh;
  }
}

.mainProjectsTitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 3vh;
  padding-left: 2vw;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 22px;
  font-weight: 500;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainProjectsTitle {
    font-size: 16px;
    padding-left: 1vw;
    margin-top: 1vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainProjectsTitle {
    font-size: 18px;
    margin-top: 1vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mainProjectsTitle {
    font-size: 22px;
    margin-top: 1vh;
  }
}

.mainExperienceBoxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainExperienceBoxWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.mainExperienceBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 21vh;
  border: 1px solid rgb(223, 212, 212);
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 375px) {
  .mainExperienceBox {
    height: 25vh;
    justify-content: flex-start;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 376px) {
  .mainExperienceBox {
    height: 24vh;
    justify-content: flex-start;
    border-radius: 8px;
    width: 98%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainExperienceBox {
    width: 97%;
    height: 20.5vh;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1081px) {
  .mainExperienceBox {
    height: 23vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mainExperienceBox {
    width: 99%;
    height: 20.5vh;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainExperienceBox {
    width: 97%;
    height: 24vh;
    border-radius: 8px;
    margin-right: 1vw;
  }
}
@media only screen and (min-width:2303px) {
  .mainExperienceBox {
    width: 100%;
    height: 24vh;
    border-radius: 8px;
  }
}

.mainExperienceTitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 3vh;
  padding-left: 2vw;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 22px;
  font-weight: 500;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainExperienceTitle {
    font-size: 16px;
    padding-left: 1vw;
    margin-top: 1vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainExperienceTitle {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mainExperienceTitle {
    font-size: 22px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainExperienceTitle {
    font-size: 24px;
    width: 90%;
  }
}
@media only screen and (min-width:2303px) {
  .mainExperienceTitle {
    font-size: 20px;
  }
}

.mainExperienceIcon {
  width: 20px;
  height: 20px;
  margin-right: 1vw;
}

.mainExperienceButtonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 15vh;
  width: 70%;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainExperienceButtonWrapper {
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainExperienceButtonWrapper {
    width: 100%;
    margin-top: 0.1vh;
  }
}
@media only screen and (min-width:2303px) {
  .mainExperienceButtonWrapper {
    width: 100%;
    margin-top: 0.1vh;
    height: 18vh;
  }
}

.mainExperienceList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  height: 6vh;
  border-radius: 4px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 3px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainExperienceList {
    width: 100%;
    font-size: 11px;
    margin-left: 1vw;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainExperienceList {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainExperienceList {
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .mainExperienceList {
    font-size: 22px;
  }
}

.mainPhotoGalleryBoxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainPhotoGalleryBoxWrapper {
    display: flex;
    flex-direction: column;
    height: auto;
  }
}

.mainPhotoGalleryBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 28vh;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 4px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainPhotoGalleryBox {
    height: 30vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainPhotoGalleryBox {
    width: 97%;
    height: 20vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .mainPhotoGalleryBox {
    width: 97%;
    height: 30vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .mainPhotoGalleryBox {
    width: 97%;
    margin-right: 1vw;
    height: 24vh;
    border-radius: 8px;
  }
}

.mainPhotoGalleryImageBox, .mainPhotoGalleryImageBox1 {
  width: auto;
  height: 30vh;
  border-radius: 8px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainPhotoGalleryImageBox, .mainPhotoGalleryImageBox1 {
    height: 29vh;
    margin-top: 10px;
    border-radius: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainPhotoGalleryImageBox, .mainPhotoGalleryImageBox1 {
    height: 15vh;
  }
}

.mainPhotoGalleryImageBox1 {
  zoom: 1.5;
  box-shadow: 2px 2px 12px 10px rgba(0, 0, 0, 0.2);
}

.mainPhotoGalleryTitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
  height: 3vh;
  padding-left: 2vw;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 22px;
  font-weight: 500;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainPhotoGalleryTitle {
    font-size: 16px;
    padding-left: 1vw;
  }
}

.mainPhotoGalleryImageWrapper {
  display: flex;
  gap: 1.5vw;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20vh;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainPhotoGalleryImageWrapper {
    display: flex;
    gap: 2vw;
    flex-direction: column;
    flex-wrap: wrap;
    width: 98%;
    height: 40vh;
    max-width: 98%;
    overflow-x: scroll;
    overflow-y: hidden;
    justify-content: flex-start;
    margin-top: 0.5vh;
  }
  .mainPhotoGalleryImageWrapper::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  .mainPhotoGalleryImageWrapper::-webkit-scrollbar-thumb {
    background: rgb(225, 176, 68);
    border-radius: 10px;
  }
  .mainPhotoGalleryImageWrapper::-webkit-scrollbar-track {
    background: rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainPhotoGalleryImageWrapper {
    height: 15vh;
  }
}

.mainPhotoGalleryImage {
  width: 150px;
  height: 210px;
  border-radius: 8px;
  cursor: pointer;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .mainPhotoGalleryImage {
    width: 120px;
    height: 165px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .mainPhotoGalleryImage {
    width: 200px;
    height: 265px;
  }
}/*# sourceMappingURL=newMain.css.map */