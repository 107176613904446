
:root {
    --rgbaVal:white;
    --rgbaVal2:white;
    --getColorVal:white;
    --val:white;
    --windowHeight: 92vh;

  }


$generalStyles: (
  backgroundColor: rgb(245, 245, 237),
  fontFamily:var(--font-family),
  fontFamilyRoboto:var(--font-familyRoboto),
  fontFamilyNoto:var(--font-familyNoto),
  fontFamilyTimes: 'Times New Roman, Times, serif',
  fontFamilyLobster: 'Lobster',
  fontFamilyZenOldMincho: 'Zen Old Mincho, Arial, Helvetica, sans-serif',
  fontFamilyAnders:'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
  windowsHeight: 92vh,
  width: 100%,
  //text sizes
  textExtraSmall: 10px,
  textSmall: 12px,
  textMedium: 20px,
  textLarge: 22px,
  textXLarge: 30px,
  textXXLarge: 40px,

  totalWidth: 100%,
  border: 2px solid darkgrey,
  color: black,
  colorwhite: white,
  colorBrown: brown,
  colorOrange: orange,
  colorYellow: yellow,
  colorGrey: grey,
  colorBeige: rgb(247, 165, 127),
  colorLightRed: rgb(228, 118, 91),
  colordarkBeige: rgb(211, 175, 114),
  colorGold: rgb(225, 176, 68),
  colorBgBeige:rgb(234, 213, 186),
  infoBeige:rgb(223, 196, 167),
  borderColor: grey,
  borderLightColor: rgb(235, 224, 224),
  selectHeight: 30px,
  windowHeight:var(--windowHeight),
//   calculatedTextAreaHeight: $textareaHeight,
  colorTransparent: transparent,
  fieldsetBorderColor: darkgrey,
  selectBorderColor: lightgrey,
  selectlistFontSize: 14px,
  menuTextColor: black,
  menuheaderFontsize: 20px,
  datalistBorderColor: rgb(223, 212, 212),
  datalistBorderColorGrey: grey,
  datalistBorderColorWhite: white,
  datalistBorderColorBlack: black,
  spinnerColor: transparent,
  datalistbackgroundColor: white,
  transparentBackgroundColor: rgba(245, 245, 237, 0.6),
  datalisttextsize: 16px,
  datalistitemColor: rgb(236, 228, 228),
  datalistitemColor2: rgb(248, 244, 244),
  transparentChangeable:var(--val),
//   transparentChangeableMenu: rgba(247, 165, 127, $menuval),
//   transparentChangeableMenu2: rgba(255, 255, 255, $menuval2),
//   transparentChangeableMenuCat: rgba(255,255,255, $menuval),
//   transparentChangeableMenuCat2: rgba(255,255,255, $menuval2),
//   transparentChangeableMenu3: rgba(224,166,193, $menuval),
//   transparentChangeableMenu4: rgba(249,230,244, $menuval2),
  transparentChangeableMenu5:var(--rgbaVal),
  transparentChangeableMenu4:var(--rgbaVal2),
  transparentChangeableMenu6:var(--getColorVal),
  buttonLoginColor: rgb(225, 176, 68),
  // tablets
  prefferedTabletListHeight:30px,
  prefferedTabletListFontSize: 14px,
  
  prefferedBorderStyleThick: 2px solid,
  prefferedBorderStyle:1px solid,
  prefferedBorderStyleNone: none,
  prefferedBorderColor:rgb(223, 212, 212),
  prefferedHeaderFontsize: 14px,
  prefferedHeaderFontsizeSmall: 14px,
  prefferedHeaderHeight: 30px,
  prefferedListHeight: 30px,
  prefferedBorderSettingStyle: none,
  prefferedBorderSettingStyle2: 2px solid black,
  sixBorderRadius: 6px,
  threeBorderRadius: 3px,
  primarySelectFontsize: 18px,
  legendFontsize: 14px,
  colorblack: black,
  appWindowsMarginTop: 70px,
  appWindowsMarginLeft: 250px,
  areaMarginTop: 50px,
  buttonklickedColor: green,
  optionFontSize: 14px,
  choralColor: rgb(249, 230, 244),
  pinkDarkColor: rgb(224, 166, 193),
  pinkLightColor: rgb(249, 230, 244),
  pinkColor: rgb(241, 135, 157),
  pinkRed: rgb(229, 64, 68),
  pinkerPink: #c12661,
  greenNatureDarkColor: #344C11,
  greenNatureColor: #778D45,
  greenNatureLightColor: #AEC670,
  greenNatureLightGray: #AEC09A,
  ActiveButtonColor: rgb(235, 18, 18),
  headerColor: grey,
  headerGreen: rgb(0,128,0),
  messageheaderColor: rgb(240,128,128),
  fontsizeSmall: 12px,
  fontSizeBig: 14px,
  fontSizeSixteen: 16px,
  fontsizeFurigana:16px,
  
  //header height
  headerAreaTabletHeight:20px,
  headerAreaLaptopHeight:26px,
  headerAreaDesktopHeight:30px,
  headerAreaLargeDesktopHeight:30px,

  tabletHeaderFontsize:32px,
  applicationScrollbarSize:5px,
  applicationHeightLaptop:82vh,
  applicationHeightTablet:75vh,

);

$ipadStyles: (
  borderBottomLeftRadius: 20px,
  borderBottomRightRadius: 20px,
  height: 83.8vh,
  width: 100%,
  backgroundColor: rgb(245, 245, 237),
);

$breakpoints: (
  mobile: "only screen and (min-width:359px)and (max-width: 599px)",
  tablet: "only screen and (min-width: 600px) and (max-width: 1438px)", // Standard tablets
 // largeTablet: "only screen and (min-width: 1025px) and (max-width: 1438px) and (orientation:landscape)", // Larger tablets like iPad Pro 12" 
 laptop: "only screen and (min-width: 1439px) and (max-width: 1911px)", // Laptops
  desktop: "only screen and (min-width: 1912px) and (max-width:2302px)", // Desktops
//  largeDesktop:"only screen and (min-width:2303px) and (max-width:2561px)", // Large desktops
  largeDesktop:"only screen and (min-width:2303px)", // Large desktops

  //  verylargeDesktop:"only screen and (min-width:2562px) and (max-width:3400)", // veryLarge desktops

  );

// $breakpoints:(
//   smallMobile: "only screen and (min-width:359px)",
//   smallPlusMobile: "only screen and (min-width:360px) and (max-width: 393px)",
//   middleMobile: "only screen and (min-width:394px) and (max-width: 412px)",
//   largeMobile: "only screen and (min-width:413px) and (max-width: 600px)",
//   smallTablet: "only screen and (max-width:1024px)and (max-height:768px) and (orientation:landscape)",
//   smallplusTablet: "only screen and (max-width:1080) and (max-height: 810px) and (max-height:768px) and (orientation:landscape)",

// );

// Mixin for applying media queries
@mixin gadgets-to($name) {
  @media #{map-get($breakpoints, $name)} {
    @content;
  }
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width:100%;
  height:100%;
object-fit: cover;
  z-index: -1;
  transform:scale(1)
 
}


