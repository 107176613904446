@import './global.scss';

.mainTopArea{
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  //overflow:hidden;
}
.mainTotalArea {
    @include gadgets-to(mobile) {
        display: grid;
        grid-template-rows: 20% 50% 20%;
        grid-template-areas:
            "a"
            "b"
            "c";
        width: 100%;
        height: 98vh;
        object-fit: cover;
        @media(max-width: 375px){
            grid-template-rows: 26% 53% 20%;
        }
        @media(min-width:376px){
            grid-template-rows: 20% 44% 20%;
        }
        @media (min-width:376px) and (max-width:385px){
            grid-template-rows: 23% 45% 24%;
        }
        @media (min-width:386px) and (max-width:394px){
            grid-template-rows: 25% 46% 26%;
        }
        @media (min-width:410px) and (max-width:415px){
            grid-template-rows: 24% 46% 26%;
        }
        @media (min-width:425px) and (max-width:429px){
            grid-template-rows: 24% 46% 30%;
        }
        @media (min-width:430px) and (max-width:435px){
            grid-template-rows: 24% 46% 34%;
        }
        @media (min-width:470px) and (max-width:481px){
            grid-template-rows: 22% 46% 21%;
        }
 
    }

    @include gadgets-to(tablet) {
        display: grid;
      //  grid-template-columns:20% 80%;
        grid-template-rows: 24% 40% 50%;
        grid-template-areas:
          "a"
          "b"
          "c";
          column-gap:7px;
        width: 100%;
        height:98vh;
        object-fit: cover;

        @media(max-width:1025px){
            grid-template-rows: 26% 52% 24%;
        }
        @media(min-width:11250px){
            grid-template-rows: 26% 52% 24%;
        }
    }
    @include gadgets-to(laptop) {
        display: grid;
        grid-template-rows: 22% 50% 29%;
        grid-template-areas:
          "a"
          "b"
          "c";
        width: 100%;
        height: 98vh;
        object-fit: cover;
    }
    @include gadgets-to(desktop) {
        display: grid;
        grid-template-rows: 22% 50% 29%;
        grid-template-areas:
          "a"
          "b"
          "c";
        width: 100%;
        height: 98vh;
        object-fit: cover;
    }
    @include gadgets-to(largeDesktop) {
        display: grid;
        grid-template-rows: 23% 52% 29%;
        grid-template-areas:
          "a"
          "b"
          "c";
        width: 100%;
        height: 98vh;
        object-fit: cover;
    }
}
.mainTotalArea1{
  @extend .mainTotalArea;
  zoom:0.9;
}
.mainHeaderWrapper{
    grid-area: a;
    display:flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items:flex-start;
    height:20vh;
   // border:1px solid red;
    background-color: black;
   @include gadgets-to(mobile) {
    height:17vh;
    @media(max-width: 375px){
        height:24vh;
        width:100%;
    }
    @media (min-width:375px) and (max-width:385px){
        height:21vh;
        width:100%;
    }
    @media (min-width:386px) and (max-width:394px){
        height:22vh;
        width:100%;
    }
    @media (min-width:410px) and (max-width:415px){
        height:22vh;
        width:100%;
    }
    @media (min-width:425px) and (max-width:429px){
        height:22vh;
        width:100%;
    }
    @media (min-width:430px) and (max-width:435px){
        height:22vh;
        width:100%;
    }
    @media (min-width:470px) and (max-width:481px){
        height:21vh;
        width:100%;
    }
    
    
  
   @include gadgets-to(tablet) {
    height:18vh;
    }
    @include gadgets-to(laptop) {
        height:16vh;
        }
    @include gadgets-to(desktop) {
        height:15.5vh;
        }
    @include gadgets-to(largeDesktop) {
        height:17vh;
        }

    }
}

.mainHeaderTitleText{
    display:flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items:center;
    color:white;
    font-size:map-get($generalStyles,textXLarge);
    font-weight: 300;
    font-family:#{map-get($generalStyles,fontFamilyAnders)};
   // margin-left:6vw;
    margin-top:3vh;
    width:98%;
    @include gadgets-to(mobile) {
        font-size:26px;
        margin-top:1vh;
        }
    @include gadgets-to(tablet) {
        font-size: 28px;
        }
        @include gadgets-to(laptop) {
            font-size: 26px;
            }
        @include gadgets-to(desktop) {
            font-size: 28px;
            }
        @include gadgets-to(largeDesktop) {
            font-size: 30px;
            }
    
}

.mainHeaderSubTitleText{
    display:flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items:center;
    color:white;
    font-size:map-get($generalStyles,textLarge);
    font-weight: 400;
    font-family:#{map-get($generalStyles,fontFamilyAnders)};
   // padding-left:5vw;
    width:98%;
@include gadgets-to(mobile) {
    font-size: 20px;
    justify-content:flex-start;
    margin-left:3vw;
    }
@include gadgets-to(tablet) {
    font-size: 22px;
    margin-left:3vw;
    }
    @include gadgets-to(laptop) {
        font-size: 20px;
        margin-left:3vw;
        }
    @include gadgets-to(desktop) {
        font-size: 26px;
        margin-left:3vw;
        }
    @include gadgets-to(largeDesktop) {
        font-size: 30px;
        margin-left:3vw;
        }

}

.mainHeaderTitleText{
    display:flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items:center;
    color:white;
    font-size:map-get($generalStyles,textXLarge);
    font-weight: 300;
    font-family:#{map-get($generalStyles,fontFamilyAnders)};
   // margin-left:6vw;
    margin-top:3vh;
    width:98%;
    @include gadgets-to(mobile) {
        font-size:26px;
        margin-top:1vh;
        margin-left:3vw;
        }
    @include gadgets-to(tablet) {
        font-size: 28px;
        margin-left:3vw;
        }
        @include gadgets-to(laptop) {
            font-size:30px;
            margin-left:3vw;
            }
        @include gadgets-to(desktop) {
            font-size: 32px;
            margin-left:3vw;
            }
        @include gadgets-to(largeDesktop) {
            font-size: 36px;
            margin-left:3vw;
            }
    
}
.mainHeaderSubTitleTextLocation{
    display:flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items:center;
    color:white;
    font-size:16px;
    font-weight: 300;
    font-family:#{map-get($generalStyles,fontFamilyAnders)};
   // margin-left:6vw;
    margin-top:12px;
    width:98%;
    @include gadgets-to(mobile) {
        font-size:13px;
        margin-top:1vh;
        margin-left:3vw;
        }
    @include gadgets-to(tablet) {
        font-size: 14px;
        margin-left:3vw;
        }
        @include gadgets-to(laptop) {
            font-size: 15px;
            margin-left:3vw;
            }
        @include gadgets-to(desktop) {
            font-size: 16px;
            margin-left:3vw;
            }
        @include gadgets-to(largeDesktop) {
            font-size: 20px;
            margin-left:3vw;
            }
}

.mainWeatherWrapper{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    //margin-top:2vh;
    width:100%;
    height:1vh;
    @include gadgets-to(mobile) {
        height: 5vh;
       justify-content: flex-start;
       align-items: flex-start;
       display:flex;
       flex-direction:row;
       width:99%;
       margin-left:3vw;
    }
    @include gadgets-to(tablet) {
        height: 3vh;
    }
    @include gadgets-to(laptop) {
        height: 2vh;
    }
    @include gadgets-to(desktop) {
        height: 3vh;
    }
    @include gadgets-to(largeDesktop) {
        height: 4vh;
    }
  }
  .mainWeather{
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    align-items:center;
    margin-top:2vh;
    width:100%;
    height:3vh;
    font-family:#{map-get($generalStyles,fontFamilyAnders)};
    color:white;
    font-size:12px;
    margin-bottom:2vh;
    margin-right:2vw;

    @include gadgets-to(mobile) {
        margin-right:0.1vw;
        justify-content:flex-start;
        font-size:10px;
        width:98%;
        @media(max-width: 370px){
            font-size:10px;
            white-space: nowrap;
        }

    }
    @include gadgets-to(tablet) {
        font-size: 11px;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 20px;
    }
  }
  .mainWeatherIcon{
    width: 50px;
    height: 50px;
    margin-right:1vw;
    color:white;
    @media(max-width: 370px){
        width: 30px;
        height: 30px;
        color:white;
    }
  }
  .mainWeatherText{
    font-size: 12px;
    font-family:#{map-get($generalStyles,fontFamilyAnders)};
  }

  .mainFlagsWrapper{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
    height:10vh;
    background-color: black;
    @include gadgets-to(mobile) {
     height:5vh;
     display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top:5px;
    }
    @include gadgets-to(desktop) {
        height: 5vh;
    }
  }
  .mainFlagArea{
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    align-items:center;
   // margin-top:2vh;
    width:98%;
    @include gadgets-to(mobile) {
        justify-content:center;
     

    }

  }
.midFlag{
    width: 30px;
    height: 30px;
    margin-right:1vw;
  //  color:white;
  cursor:pointer;
  @include gadgets-to(mobile) {
    width: 24px;
    height: 24px;
  }
}

.mainAreaB{
    grid-area:b;
    display:flex;
    flex-direction:column;
   // justify-content:center;
    align-items:center;
    height:52vh;
    width:100%;
    @media(min-width:383px) and (max-width:385px){
        height: 55vh;
    }
    @media(min-width:410px) and (max-width:415px){
        height: 58vh;
    }

    @include gadgets-to(desktop) {
        height: 50vh;
        width:99%;
     
    }
  
}
.mainAreaBWrapper{
    display:flex;
    flex-direction:column;
  //  justify-content:center;
    align-items: center;
    width:97%;
    height:58vh;    
    border:1px solid map-get($generalStyles,datalistBorderColor);

    @include gadgets-to(mobile) {
        @media(min-width:380px)and (max-width:385px){
            width:97%;
            height: 46vh;
        }
        @media(min-width:385px) and (max-width:393px){
            width:97%;
            height: 48vh;
        }   
        @media(min-width:410px) and (max-width:415px){
            width:97%;
            height: 48vh;
            border-radius: 8px;
        }
        @media(min-width:425px) and (max-width:429px){
            width:97%;
            height: 50vh;
        }
        @media(min-width:470px) and (max-width:481px){
            width:97%;
            height: 45vh;
        }
   
    }
    @include gadgets-to(tablet) {
        width:97%;
        height: 56vh;
        @media(max-width:1025px){
            height:57vh;
            border-radius:8px;
        }
        @media(min-width:1026px) and (max-width:1115px){
            height:57vh;
            border-radius:8px;
        }
    }
    @include gadgets-to(laptop) {
        width:99%;
        height: 48vh;
        border-radius: 8px;
    }
    @include gadgets-to(desktop) {
        width:98%;
        height: 50vh;
        border-radius: 8px;
    }
    @include gadgets-to(largeDesktop) {
        width:97%;
        height: 50vh;
        border-radius: 8px;
    }
}
.mainAreaC{
    grid-area: c;
    margin-top:2vh;
    width:90%;
   // height:30vh;
   @include gadgets-to(mobile) {
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    width:100%;
    margin-top:0.5vh;
    }
    @include gadgets-to(tablet) {
        display:flex;
        flex-direction: column;
        justify-content:center;
        align-items:center;
        width:100%;
        margin-top:0.5vh;
    }
    @include gadgets-to(laptop) {
        display:flex;
        flex-direction: column;
      //  justify-content:center;
        align-items:center;
        width:100%;
        margin-top:0.5vh;

    }
    @include gadgets-to(desktop) {
        display:flex;
        flex-direction: column;
       // justify-content:center;
        align-items:center;
        width:100%;
      //  margin-top:2.5vh;
    }
    @include gadgets-to(largeDesktop) {
        display:flex;
        flex-direction: column;
      //  justify-content:center;
        align-items:center;
        width:100%;
        margin-top:0.5vh;
    }

}


.mainAboutMeText{
    font-size:18px;
    font-weight:500;
    font-family: #{map-get($generalStyles,fontFamilyAnders)};
    width:98%;
    height:2vh;
    margin-left:2vw;
    @include gadgets-to(mobile) {
        font-size: 14px;
        margin-left:1vw;
        height:1vh;
        margin-top:1vh;
    }
    @include gadgets-to(tablet) {
        font-size: 22px;
        font-family:#{map-get($generalStyles,fontFamilyAnders)};
        margin-left:1vw;
        height:2vh;
        @media(max-width:1025px){
            height:1vh;
        }

    }
    @include gadgets-to(laptop) {
        font-size: 24px;
        font-family:#{map-get($generalStyles,fontFamilyAnders)};
        margin-left:1vw;
        height:2vh;
    }
    @include gadgets-to(desktop) {
        font-size: 26px;
        font-family:#{map-get($generalStyles,fontFamilyAnders)};
        margin-left:1vw;
        height:2vh;
    }
    @include gadgets-to(largeDesktop) {
        font-size: 32px;
        font-family:#{map-get($generalStyles,fontFamilyAnders)};
        margin-left:1vw;
        height:2vh;
    }
  }

  
  .mainMidPhoto{
    display:flex;
    flex-direction: column;
     //justify-content:center;
     align-items:center;
    // margin-left:1vw;
     width:25%;
     height:48vh;
     @include gadgets-to(mobile) {
         width:100%;
         height: 20vh;
     }
        @include gadgets-to(tablet) {
            width:100%;
            height: 25vh;
    }
    @include gadgets-to(laptop) {
        width:100%;
        height: 25vh;

    }
    @include gadgets-to(desktop) {
        width:100%;
        height: 25vh;
    }
    @include gadgets-to(largeDesktop) {
        width:100%;
        height: 25vh;
    }

    .mainMidPhotoImg{
        width:60%;
        height:70%;
    
        @include gadgets-to(mobile) {
            width:20vh;
            height: 20vh;
            border-radius: 50%;
        }
        @include gadgets-to(tablet) {
            width:25vh;
            height: 25vh;
            border-radius: 50%;
        }
        @include gadgets-to(laptop) {
            width:25vh;
            height: 25vh;
            border-radius: 50%;
        }
        @include gadgets-to(desktop) {
            width:25vh;
            height: 25vh;
            border-radius: 50%;
        }
        @include gadgets-to(largeDesktop) {
            width:25vh;
            height: 25vh;
            border-radius: 50%;
        }
    }
  }
    .mainMidInfoText{
        font-family: #{map-get($generalStyles,fontFamilyAnders)};
        font-size: map-get($generalStyles,textMedium);
        padding:5px;
        
        @include gadgets-to(mobile) {
            font-size: 12px;
        }
        @include gadgets-to(tablet) {
            font-size: 15px;
            width:80%;
        }
        @include gadgets-to(laptop) {
            font-size: 18px;
            width:80%;
            margin-bottom:5px;
        }
        @include gadgets-to(desktop) {
            font-size: 20px;
            width:80%;
        }
        @include gadgets-to(largeDesktop) {
            font-size: 24px;
            width:80%;
        }
    
}


    .mainMidButtonsWrapper{
        display:flex;  
        flex-direction:row;
        justify-content:space-evenly;
        align-items:center;
        width:100%;
        @include gadgets-to(tablet) {
            width:50%;
            justify-content:space-evenly;
            margin-bottom:5px;
        }
        @include gadgets-to(laptop) {
            width:50%;
            justify-content:space-evenly;
            margin-bottom:5px;
        }
        @include gadgets-to(desktop) {
            width:50%;
            justify-content:space-evenly;
            margin-bottom:5px;
        }
        @include gadgets-to(largeDesktop) {
            width:50%;
            justify-content:space-evenly;
            margin-bottom:5px;
        }
    }

    .mainInfoButton{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border:1px solid map-get($generalStyles,datalistBorderColor);
        border-radius:6px;
        background-color:white;
        color:map-get($generalStyles,buttonTextColor);
        width:9vw;
        height:25px;
        padding:2px;
        margin-left:8px;
        cursor:pointer;
        @include gadgets-to(mobile) {
            width:auto;
            height: 20px;
            margin:3px;
            padding:3px;
        }
  
    }
    .mainIcons{
        width: 18px;
        height: 18px;
        stroke-width: 2;
          }
          .mainIconText{
            font-family: #{map-get($generalStyles,fontFamilyAnders)};
            font-size: 16px;
            margin-left:1vw;
            @include gadgets-to(mobile) {
                font-size: 12px;
            }
            @include gadgets-to(largeDesktop) {
                font-size: 22px;
            }
        }
    .mainInfoButtonChat{
        display: flex;
        flex-direction: row;
        justify-content:flex-start;
        align-items: center;
        border:1px solid map-get($generalStyles,datalistBorderColor);
        border-radius:6px;
        background-color:white;
        color:map-get($generalStyles,buttonTextColor);
        width:auto;
        height:25px;
        padding:2px;
        margin-left:10px;
        cursor:pointer;
        //padding:10px;
        // hover
        &:hover{
            background-color: map-get($generalStyles,colorGold);
            color:white;
            box-shadow: 5px 5px 12px 2px rgba(71, 70, 70, 0.2);
        }
        @include gadgets-to(mobile) {
            height: 20px;
            width:auto;
            padding:2px;
            margin:3px;
            padding:3px;
    
        }
        @include gadgets-to(tablet) {
            height: 20px;
            width:auto;
            padding:2px;
            margin:3px;
            padding:5px;
        }
        @include gadgets-to(laptop) {
            height: 25px;
            width:auto;
            padding:2px;
            margin:3px;
            padding:5px;
        }
        @include gadgets-to(desktop) {
            height: 20px;
            width:auto;
            padding:2px;
            margin:3px;
            padding:5px;
        }
        @include gadgets-to(largeDesktop) {
            height: 25px;
            width:auto;
            padding:2px;
            margin:3px;
            padding:5px;
        }
    }

    .mainIconTextChat{
        font-family: #{map-get($generalStyles,fontFamilyAnders)};
        font-size: 16px;
        margin-left:1vw;
        width:auto;
        // no break
        white-space: nowrap;
        @include gadgets-to(mobile) {
            font-size: 12px;
        }
        @include gadgets-to(largeDesktop) {
            font-size: 22px;
        }
    }


    .chat-container {
        position:absolute;
        top:42vh;
        right:15vw;
        width: 500px;
       // max-width:40vw;
        height:40vh;
        margin: 0 auto;
        border: 1px solid #ddd;
        border-radius: 8px;
        overflow: hidden;
        background-color: #f7f7f7;
        box-shadow: 12px 12px 10px 2px rgba(0, 0, 0, 0.5);
        z-index: 99;
        @include gadgets-to(mobile) {
            width: 300px;
            height: 38vh;
            top:64vh;
            right: 5vw;
        }
        @include gadgets-to(tablet) {
            width: 400px;
            height: 50vh;
        }
      }
      
      .chat-messages {
        padding: 16px;
        height: 300px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 10px;
        @include gadgets-to(mobile) {
            height: 30vh;
        }
        @include gadgets-to(tablet) {
            height: 41.5vh;
        }
      }
      
      .message {
        padding: 10px;
        border-radius: 8px;
        max-width: 75%;
      }
      
      .user {
        align-self: flex-end;
        background-color:green;
        color: white;
      }
      
      .assistant {
        align-self: flex-start;
        background-color: #e5e5e5;
        color: black;
      }
      
      .typing-indicator {
        display: flex;
        gap: 4px;
      }
      
      .dot {
        width: 8px;
        height: 8px;
        background-color: #333;
        border-radius: 50%;
        animation: blink 1s infinite both;
      }
      
      .dot:nth-child(1) { animation-delay: 0s; }
      .dot:nth-child(2) { animation-delay: 0.3s; }
      .dot:nth-child(3) { animation-delay: 0.6s; }
      
      @keyframes blink {
        0%, 100% { opacity: 0.2; }
        50% { opacity: 1; }
      }
      
      .message-form {
        display: flex;
        border-top: 1px solid #ddd;
      }
      
      .message-form input {
        flex: 1;
        padding: 10px;
        border: none;
        outline: none;
      }
      
      .message-form button {
        padding: 10px;
        border: none;
        background-color: #007bff;
        color: white;
        cursor: pointer;
      }




      .mainMidSelectWrapper{
        display:flex;
        flex-direction: column;
        justify-content:center;
        align-items:center;
        margin-top:2vh;
        width:100%;
        @include gadgets-to(mobile) {
            width:100%;
            margin-top:0.1vh;
        }
        @include gadgets-to(tablet) {
            width:100%;
            margin-top:0.1vh;
        }
        @include gadgets-to(laptop) {
            width:100%;
            margin-top:0.1vh;
        }
        @include gadgets-to(desktop) {
            width:100%;
            margin-top:0.1vh;
        }
        @include gadgets-to(largeDesktop) {
            width:100%;
            margin-top:0.1vh;
        }
    }
    .mainMidSelectBox{
        display:flex;
        flex-direction:row;
        justify-content:flex-start;
        align-items:center;
        width:90%;
        height:6vh;
        border:1px solid map-get($generalStyles,datalistBorderColor);
        border-radius: 4px;
       // margin-top:1vh;
       padding:15px;
       @include gadgets-to(mobile) {
        padding:4px;
        width:95%;
        justify-content:center;
        }
        @include gadgets-to(tablet) {
            padding:4px;
            width:95%;
            justify-content:center;
            }
        @include gadgets-to(laptop) {
            padding:4px;
            width:95%;
            justify-content:center;
            }
        @include gadgets-to(desktop) {
            padding:4px;
            width:95%;
            justify-content:center;
            }
        @include gadgets-to(largeDesktop) {
            padding:6px;
            width:95%;
            justify-content:center;
            }
    }


    .mainMidSelectButton{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border:1px solid map-get($generalStyles,datalistBorderColor);
        border-radius:6px;
        color:map-get($generalStyles,buttonTextColor);
        width:auto;
        height:25px;
        padding:3px;
        margin-left:1vw;
        font-family: #{map-get($generalStyles,fontFamilyAnders)};
        cursor:pointer;
        white-space:nowrap;
     
        @include gadgets-to(mobile) {
          font-size: 12px;
         
    
        }
        @include gadgets-to(tablet) {
            font-size: 14px;
        }
        @include gadgets-to(laptop) {
            font-size: 16px;
            padding:6px;
        }
        @include gadgets-to(desktop) {
            font-size: 14px;
            padding:8px;
        }
        @include gadgets-to(largeDesktop) {
            font-size: 20px;
            padding:10px;
        }
    }
    .mainMidSelectButtonON{
        @extend .mainMidSelectButton;
        background-color: map-get($generalStyles,colorGold);
        color:white;
        box-shadow: 5px 5px 12px 2px rgba(71, 70, 70, 0.2);
        cursor:pointer;
    }


    .mainMidInfoWrapper{
        grid-area:c;
        display:flex;
        flex-direction: column;
       // justify-content:center;
        align-items:center;
      //  margin-top:2vh;
        width:100%;
        height:20vh;
        @include gadgets-to(desktop) {
            height:20vh;
            width:100%;
            margin-top:1vh;
        }
    
    }
    .mainMidInfoBox{
        display:flex;
        flex-direction: column;
       // justify-content:center;
        align-items:flex-start;
        width:100%;
        height:45vh;
       // border:1px solid map-get($generalStyles,datalistBorderColor);
        border-radius: 4px;
       // margin-top:1vh;
       @include gadgets-to(mobile) {
        width:100%;
        margin-top:1vh;
        height:42vh;
        border-radius: 8px;
        }
        @include gadgets-to(laptop) {
        width:100%;
        height: 42vh;
        }

       @include gadgets-to(desktop) {
        width:100%;
       // margin-top:2vh;
        height: 36vh;
    
       }
        @include gadgets-to(largeDesktop) {
         width:97%;
       //  margin-top:3vh;
         height: 36vh;
         border-radius: 8px;
    }
    }


    .mainMidSkillsBoxWrapper{
   
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
       // margin-top:2vh;
        width:100%;
       // box-shadow: 2px 2px 12px 2px rgba(71, 70, 70, 0.2);
    }
    .mainMidSkillsBox{
        display:flex;
        flex-direction: column;
       // justify-content:center;
        align-items:flex-start;
        width:100%;
        height:17vh;
        border:1px solid map-get($generalStyles,datalistBorderColor);
        border-radius: 4px;
        @include gadgets-to(mobile) {
        height:20vh;
        width:96%;
        border-radius: 8px;
        }
        @include gadgets-to(tablet) {
        width:97%;
        height: 20vh;
        }
        @include gadgets-to(laptop) {
        width:99%;
        height: 20vh;
        border-radius: 8px;
        }

       @include gadgets-to(desktop) {
        width:97%;
      //  margin-top:2vh;
        height: 22vh;
        border-radius: 8px;
        margin-right:1vw;
       }
        @include gadgets-to(largeDesktop) {
         width:100%;
       //  margin-top:3vh;
         height: 20vh;
            border-radius: 8px;
    }
    }
    
    .mainMidSkillsTitle{
        display:flex;
        flex-direction: row;
        justify-content:flex-start;
        align-items:flex-start;
        width:10%;
        height:3vh;
        padding-left:2vw;
        font-family:#{map-get($generalStyles,fontFamilyAnders)};
        font-size: map-get($generalStyles,textLarge);
        font-weight:500;
        margin-top:1vh;
        @include gadgets-to(mobile) {
            font-size: 16px;
            margin-top:1vh;
            padding-left:1vw;
        }
    }
    .mainMidSkillButtonWrapper{
        display:flex;
        flex-direction: row;
        justify-content:flex-start;
        flex-wrap:wrap;
        align-items:center;
        height:7vh;
        @include gadgets-to(mobile) {
            width:100%;
        }
    }
    .mainMidSkillsButton{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border:1px solid map-get($generalStyles,datalistBorderColor);
        border-radius:6px;
        background-color:white;
        color:map-get($generalStyles,buttonTextColor);
        width:auto;
        height:25px;
        padding:10px;
        margin-left:1vw;
        margin-top:1vh;
        font-family: #{map-get($generalStyles,fontFamilyAnders)};
        @include gadgets-to(mobile) {
            height:18px;
            padding:2px;
            font-size: 12px;
            font-family: #{map-get($generalStyles,fontFamilyAnders)};
        }
        @include gadgets-to(tablet) {
            height:20px;
            padding:5px;
        }
        @include gadgets-to(desktop) {
            height:25px;
        }
        @include gadgets-to(largeDesktop) {
            height:25px;
            font-size: 20px;
        }
     
    }
    .mainMidSkillsButton1{
        @extend .mainMidSkillsButton;
        background-color: map-get($generalStyles,colorGold);
        color:white;
        box-shadow: 5px 5px 12px 2px rgba(71, 70, 70, 0.2);
    }

    .mainProjectsBoxWrapper{
        grid-area:c;
        display:flex;
        flex-direction: column;
        justify-content:center;
        align-items:center;
       // margin-top:2vh;
        width:100%;
    }
    .mainProjectsBox{
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:flex-start;
        width:100%;
        height:21vh;
        border:1px solid map-get($generalStyles,datalistBorderColor);
       // border-radius: 4px;
        //margin-top:2vh;
        @include gadgets-to(mobile) {
        height:25vh;
        justify-content: flex-start;
        width:97%;
        margin-top:1vh;
        height:18vh;
        border-radius: 8px;
        }
        @include gadgets-to(tablet) {
        width:97%;
        height: 20vh;
        align-items:flex-start;
        justify-content: flex-start;
        margin-top:1vh;

        }
        @include gadgets-to(laptop) {
        width:99%;
        height: 20vh;
        align-items:flex-start;
        justify-content: flex-start;
        margin-top:1vh;
        border-radius: 8px;
        }
         @include gadgets-to(desktop) {
        width:97%;
        //  margin-top:2vh;
        height: 24vh;
        border-radius: 8px;
        margin-right:1vw;
        }
        @include gadgets-to(largeDesktop) {
         width:100%;
        //  margin-top:3vh
            height: 20vh;
            border-radius: 8px;
        }
    
    }
    .mainProjectsButtonWrapper{
        display:flex;
        flex-direction:column;
        justify-content:flex-start;
        align-items:center;
        height:12vh;
        width:70%;
        margin-top:1vh;
        @include gadgets-to(mobile) {
            width:98%;
            margin-left:1vw;
        }
    }
    .mainProjectsList{
        display:flex;
        flex-direction: row;
        justify-content:flex-start;
        align-items:center;
        width:90%;
        height:6vh;
       // border:1px solid map-get($generalStyles,datalistBorderColor);
        border-radius: 4px;
       // margin-top:1vh;
       @include gadgets-to(mobile) {
        width:98%;
       // white-space: nowrap;
        font-size:12px;
        width:100%;
        }
        @include gadgets-to(desktop) {
            font-size:20px;
            font-family: #{map-get($generalStyles,fontFamilyAnders)};
            width:97%;
            height:3vh;
        }
        @include gadgets-to(largeDesktop) {
        font-size:24px;
        font-family: #{map-get($generalStyles,fontFamilyAnders)};
        width:97%;
        height:3vh;
        }
     
    }


    .mainProjectsTitle{
        display:flex;
        flex-direction: row;
        justify-content:flex-start;
        align-items:flex-start;
        width:100%;
        height:3vh;
        padding-left:2vw;
        font-family:#{map-get($generalStyles,fontFamilyAnders)};
        font-size: map-get($generalStyles,textLarge);
        font-weight:500;
        @include gadgets-to(mobile) {
            font-size: 16px;
            padding-left:1vw;
            margin-top: 1vh;
        }
        @include gadgets-to(tablet) {
            font-size: 18px;
            margin-top:1vh;
        }
        @include gadgets-to(laptop) {
            font-size: 22px;
            margin-top:1vh;
        }
    }

    .mainExperienceBoxWrapper{
        display:flex;
        flex-direction: column;
        justify-content:center;
        align-items:center;
      //  margin-top:2vh;
        width:100%;
        @include gadgets-to(desktop) {
            width:100%;
            display:flex;
            flex-direction: column;
            justify-content:center;
            align-items:center;
        }
    }
    .mainExperienceBox{
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:flex-start;
        width:100%;
        height:21vh;
        border:1px solid map-get($generalStyles,datalistBorderColor);
       // border-radius: 4px;
        @include gadgets-to(mobile) {
        @media(max-width: 375px){
            height:25vh;
            justify-content: flex-start;
        }
        @media(min-width:376px){
            height:24vh;
            justify-content: flex-start;
            border-radius: 8px;
            width:98%;
        }
    }
        @include gadgets-to(tablet) {
        width:97%;
        height: 20.5vh;
        align-items:flex-start;
        justify-content: flex-start;
        border-radius: 8px;
        @media(max-width:1081px){
            height:23vh;
        }
    }
    @include gadgets-to(laptop) {
        width:99%;
        height: 20.5vh;
        align-items:flex-start;
        justify-content: flex-start;
        border-radius: 8px;
    }
       @include gadgets-to(desktop) {
        width:97%;
      //  margin-top:2vh;
        height: 24vh;
        border-radius: 8px;
        margin-right:1vw;
       }
        @include gadgets-to(largeDesktop) {
         width:100%;
      //   margin-top:3vh;
         height: 24vh;
         border-radius: 8px;
    }
    }


    .mainExperienceTitle{
        display:flex;
        flex-direction: row;
        justify-content:flex-start;
        align-items:flex-start;
        width:100%;
        height:3vh;
        padding-left:2vw;
        font-family:#{map-get($generalStyles,fontFamilyAnders)};
        font-size: map-get($generalStyles,textLarge);
        font-weight:500;
        @include gadgets-to(mobile) {
            font-size: 16px;
            padding-left:1vw;
            margin-top:1vh;
        }
        @include gadgets-to(tablet) {
            font-size: 18px;
        }
        @include gadgets-to(laptop) {
            font-size: 22px;
        }
        @include gadgets-to(desktop) {
            font-size: 24px;
            width:90%;
        }
        @include gadgets-to(largeDesktop) {
            font-size: 20px;
        }
    }
    .mainExperienceIcon{
        width: 20px;
        height: 20px;
        margin-right:1vw;
    }

    .mainExperienceButtonWrapper{
        display:flex;
        flex-direction:column;
        justify-content:flex-start;
        align-items:center;
        height:15vh;
        width:70%;
        margin-top:1vh;
        @include gadgets-to(mobile) {
            width:100%;
    
    }
    @include gadgets-to(tablet) {
        width:100%;
        margin-top:0.1vh;
    }
    @include gadgets-to(largeDesktop) {
        width:100%;
        margin-top:0.1vh;
        height:18vh;
    }
}
    .mainExperienceList{
        display:flex;
        flex-direction: row;
        justify-content:flex-start;
        align-items:center;
        width:90%;
        height:6vh;
        border-radius: 4px;
        font-family: #{map-get($generalStyles,fontFamilyAnders)};
        font-size:map-get($generalStyles,textsmall);
        margin-top:3px;
        @include gadgets-to(mobile) {
            width:100%;
            font-size:11px;
            margin-left:1vw;
        }
        @include gadgets-to(tablet) {
            font-size:14px;
     
        }
        @include gadgets-to(desktop) {
            font-size:18px;
        }
        @include gadgets-to(largeDesktop) {
            font-size:22px;
        }
    }

    .mainPhotoGalleryBoxWrapper{
        display:flex;
        flex-direction: column;
        justify-content:center;
        align-items:center;
       // margin-top:1vh;
        width:100%;
        @include gadgets-to(mobile) {
          display:flex;
          flex-direction: column;
          height:auto;
    
        }
    }
    .mainPhotoGalleryBox{
        display:flex;
        flex-direction:column;
        //justify-content:center;
      //  align-items:flex-start;
        width:100%;
        height:28vh;
        border:1px solid map-get($generalStyles,datalistBorderColor);
        border-radius: 4px;
      //  margin-top:2vh;
      @include gadgets-to(mobile) {
        height:30vh;
        }
        @include gadgets-to(tablet) {
        width:97%;
        height: 20vh;
        }
        @include gadgets-to(laptop) {
        width:97%;
        height: 30vh;
        }
         @include gadgets-to(desktop) {
        width:97%;
        margin-right: 1vw;
        //  margin-top:2vh;
        height: 24vh;
        border-radius: 8px;
         }
   
      }
    
    .mainPhotoGalleryImageBox{
   // border:1px solid map-get($generalStyles,datalistBorderColor);
    width:auto;
    height:30vh;
    border-radius: 8px;
    //margin-left:0.5vw;
    @include gadgets-to(mobile) {
        height:29vh;
        margin-top:10px;
        border-radius: 12px;
    }
    @include gadgets-to(tablet) {
        height: 15vh;
    }
    }
    
    .mainPhotoGalleryImageBox1{
        @extend .mainPhotoGalleryImageBox;
        zoom: 1.5;
        box-shadow: 2px 2px 12px 10px rgba(0,0,0,0.2);
     
    }
    
    .mainPhotoGalleryTitle{
        display:flex;
        flex-direction: row;
        justify-content:flex-start;
        align-items:flex-start;
        width:90%;
        height:3vh;
        padding-left:2vw;
        font-family:#{map-get($generalStyles,fontFamilyAnders)};
        font-size: map-get($generalStyles,textLarge);
        font-weight:500;
        @include gadgets-to(mobile) {
            font-size: 16px;
            padding-left:1vw;
        }
    }
    .mainPhotoGalleryImageWrapper{
        display:flex;
        gap:1.5vw;
        flex-direction: row;
        justify-content:center;
        align-items:center;
        width:100%;
        height:20vh;
        margin-top:1vh;
      //  margin-left:2vw;
      @include gadgets-to(mobile) {
        display:flex;
        gap:2vw;
        flex-direction:column;
        flex-wrap:wrap;
        width:98%;
        height:40vh;
        max-width:98%;
        overflow-x:scroll;
        overflow-y:hidden;
        justify-content: flex-start;
        margin-top:0.5vh;
    // scrollbar
        &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
        }
        &::-webkit-scrollbar-thumb {
            background: map-get($generalStyles,colorGold);
            border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
            background: map-get($generalStyles,datalistBorderColor);
        }
      }
    
    @include gadgets-to(tablet) {
        height: 15vh;
    }
    }
    .mainPhotoGalleryImage{
      width:150px;
        height:210px;
       // margin-left:2vw;
        border-radius: 8px;
        cursor:pointer;
        @include gadgets-to(tablet) {
            width: 120px;
            height: 165px;
        }
        @include gadgets-to(mobile) {
            width: 200px;
            height: 265px;
        }
      }
    